
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.error-page {
  min-height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  padding: 40px 0;
  position: relative;
  overflow: hidden;

  &__code {
    font-size: 35vw;
    line-height: 1;
    font-weight: 600;
    color: #d8d4ea;
    text-shadow: (4px 4px 8px rgba(29, 20, 49, 0.08));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__buttons {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 40vw;
    height: 40vw;
    position: absolute;
    bottom: 5%;
    right: 5%;
    transform: translate(50%, 50%);
    background: radial-gradient(#7b66ff, $color-client-portal-logo);
    z-index: 0;
    user-select: none;
    pointer-events: none;

    @include for-size(phone-portrait-down) {
      display: none;
    }
  }
}


          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.maintenance-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  padding: 40px 0 0;
  position: relative;
  overflow: hidden;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 40vw;
    height: 40vw;
    position: absolute;
    bottom: 5%;
    right: 5%;
    transform: translate(50%, 50%);
    background: radial-gradient($primary-color, $color-client-portal-logo);
    z-index: 0;
    user-select: none;
    pointer-events: none;

    @include for-size(phone-portrait-down) {
      display: none;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: $gap;
  }

  &__image {
    height: 168px;
    width: 251px;
    margin-bottom: $gap;

    @include for-size(phone-portrait-down) {
      width: 120px;
      height: 98px;
    }
  }
}
